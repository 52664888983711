exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-products-all-products-jsx": () => import("./../../../src/pages/allProducts/allProducts.jsx" /* webpackChunkName: "component---src-pages-all-products-all-products-jsx" */),
  "component---src-pages-book-now-jsx": () => import("./../../../src/pages/book-now.jsx" /* webpackChunkName: "component---src-pages-book-now-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-home-call-to-action-section-jsx": () => import("./../../../src/pages/home/callToActionSection.jsx" /* webpackChunkName: "component---src-pages-home-call-to-action-section-jsx" */),
  "component---src-pages-home-landing-section-jsx": () => import("./../../../src/pages/home/landingSection.jsx" /* webpackChunkName: "component---src-pages-home-landing-section-jsx" */),
  "component---src-pages-home-product-slider-section-jsx": () => import("./../../../src/pages/home/productSliderSection.jsx" /* webpackChunkName: "component---src-pages-home-product-slider-section-jsx" */),
  "component---src-pages-home-services-section-jsx": () => import("./../../../src/pages/home/servicesSection.jsx" /* webpackChunkName: "component---src-pages-home-services-section-jsx" */),
  "component---src-pages-home-testimonial-section-jsx": () => import("./../../../src/pages/home/testimonialSection.jsx" /* webpackChunkName: "component---src-pages-home-testimonial-section-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-story-and-vision-jsx": () => import("./../../../src/pages/our-story-and-vision.jsx" /* webpackChunkName: "component---src-pages-our-story-and-vision-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-products-sanity-product-slug-current-jsx": () => import("./../../../src/pages/products/{sanityProduct.slug__current}.jsx" /* webpackChunkName: "component---src-pages-products-sanity-product-slug-current-jsx" */),
  "component---src-pages-safety-jsx": () => import("./../../../src/pages/safety.jsx" /* webpackChunkName: "component---src-pages-safety-jsx" */)
}

